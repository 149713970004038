import Layout from "components/Layout";
import { Provider } from "next-auth/client";

import "../styles/globals.css";

function App({ Component, pageProps }) {
  return (
    <Provider session={pageProps.session}>
      <Layout session={pageProps.session}>
        <Component {...pageProps} />
      </Layout>
    </Provider>
  );
}

export default App;
