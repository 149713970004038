import Head from "next/head";
import { Session } from "next-auth";

import NavBar from "components/NavBar";

type LayoutProps = {
  session: Session;
  children?: React.ReactNode;
};

export default function Layout({ children, session }: LayoutProps) {
  return (
    <div>
      <Head>
        <title>Profilus</title>
      </Head>
      <NavBar session={session} />
      <main className="container">{children}</main>
    </div>
  );
}
