import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";

export default function Tabs() {
  const router = useRouter();
  const { id, tab } = router.query;

  const tabs: { key: string; label: string }[] = [
    { key: "details", label: "Details" },
    { key: "events", label: "Events" },
    { key: "locations", label: "Locations" },
  ];

  return (
    <ul className="navbar-nav">
      {tabs.map(({ key, label }) => {
        const link: string = `/search?id=${id}&tab=${key}`;
        return (
          <li key={key} className="nav-item">
            <Link href={link}>
              <a className={`nav-link ${tab === key ? "active" : ""}`}>
                {label}
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
