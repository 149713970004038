import Link from "next/link";
import { useRouter } from "next/router";
import { Session } from "next-auth";

import Tabs from "components/Tabs";

const logoStyle: React.CSSProperties = {
  maxHeight: "25px",
};

type NavBarProps = {
  session: Session;
};

export default function NavBar({ session }: NavBarProps) {
  if (!session) {
    return null;
  }

  const { pathname } = useRouter();

  const lightNavBar = pathname === "/";

  const additionalClassnames = !lightNavBar
    ? "navbar-dark bg-dark"
    : "navbar-light";

  return (
    <nav
      className={`navbar navbar-expand ps-5 pe-5 d-flex justify-content-between ${additionalClassnames}`}
    >
      <div
        style={lightNavBar ? { visibility: "hidden" } : {}}
        className="d-flex"
      >
        <Link href="/">
          <a className="navbar-brand">
            <img src="/logo.png" style={logoStyle} className="me-2" />
            Profilus
          </a>
        </Link>
        <Tabs />
      </div>
      <div>
        <ul className="navbar-nav">
          <li className="nav-item">
            <span className="nav-link">{session?.user.name}</span>
          </li>
        </ul>
      </div>
    </nav>
  );
}
